import style from './common.module.css'
import type { IconProps } from '../../_types'

export function OpenLinkIcon({
  title = 'Go to page',
  className = style.defaultClassName,
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={[style.wrapper, className].join(' ')}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1717 1.82842C18.5622 2.21894 18.5622 2.85211 18.1717 3.24263L9.17171 12.2426C8.78118 12.6332 8.14802 12.6332 7.75749 12.2426C7.36697 11.8521 7.36697 11.2189 7.75749 10.8284L16.7575 1.82842C17.148 1.43789 17.7812 1.43789 18.1717 1.82842Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 1C20 1.55229 19.5523 2 19 2L11 2C10.4477 2 10 1.55228 10 1C10 0.447715 10.4477 -4.82823e-08 11 0L19 6.99382e-07C19.5523 7.47665e-07 20 0.447716 20 1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6.99382e-07C19.5523 7.47665e-07 20 0.447716 20 1V9C20 9.55229 19.5523 10 19 10C18.4477 10 18 9.55229 18 9L18 1C18 0.447716 18.4477 6.02818e-07 19 6.99382e-07Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 3.34315 1.34315 2 3 2H6C6.55228 2 7 2.44772 7 3C7 3.55229 6.55228 4 6 4H3C2.44772 4 2 4.44772 2 5V17C2 17.5523 2.44772 18 3 18H15C15.5523 18 16 17.5523 16 17V14C16 13.4477 16.4477 13 17 13C17.5523 13 18 13.4477 18 14V17C18 18.6569 16.6569 20 15 20H3C1.34315 20 0 18.6569 0 17V5Z"
      />
    </svg>
  )
}
