import style from './common.module.css'
import { HOUSE_RULES_URL, VIDZING_HOME_PAGE } from '@root/constants'
import { LinkList } from './link-list'
import { LogoCopyright } from './logo-copyright'
import { HELP_VIDZING_EMAIL_LINK } from '@shared/constants/root.constants'

export type Props = {
  className?: string
}

export function Nav({ className }: Props): JSX.Element {
  return (
    <nav className={[style.nav, className].join(' ')}>
      <LinkList
        title={
          <a href={VIDZING_HOME_PAGE} rel="noreferrer">
            Vidzing
          </a>
        }
        className={style.vidzing}
      >
        <li>
          <a href={HELP_VIDZING_EMAIL_LINK} className={style.item}>
            Help
          </a>
        </li>
        <li>
          <a href={HOUSE_RULES_URL} rel="noreferrer">
            House Rules
          </a>
        </li>
        <li>
          <a href={HELP_VIDZING_EMAIL_LINK} className={style.item}>
            Contact us
          </a>
        </li>
        {/* TODO: No more hubspot, direct to accessibility statement on Vidzing when ready */}
        {/*<li>*/}
        {/*  <a href={''''} rel="noreferrer">*/}
        {/*    Accessibility Statement*/}
        {/*  </a>*/}
        {/*</li>*/}
      </LinkList>
      <LinkList title={'Creators'} className={style.creators}>
        <li>
          <a href={VIDZING_HOME_PAGE} rel="noreferrer">
            Become a creator
          </a>
        </li>
        {/* TODO: No more hubspot, direct to new blog hosting when ready */}
        {/*<li>*/}
        {/*  <a href={''} rel="noreferrer">*/}
        {/*    Blog*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <a href={''} rel="noreferrer">*/}
        {/*    Case Studies*/}
        {/*  </a>*/}
        {/*</li>*/}
      </LinkList>
      <LogoCopyright className={style.logoCopyright} />
    </nav>
  )
}
