export default function getHostName(url: string): string {
  let websiteDomain = ''
  try {
    websiteDomain = new URL(url).hostname
  } catch (e) {
    websiteDomain = url
  }

  return websiteDomain
}
