// Importing styles
import style from './loading.module.css'

// Reusing loader component
import { Loader } from '../_buttons/button/loader/index'

export type Props = {
  background?: string
}

export function LoadingBox({ background }: Props): JSX.Element {
  /** Seting background colour as variable to use in CSS */
  const cssVariables: Record<string, unknown> = {
    '--colour': background,
  }

  return (
    <div className={style.container} style={cssVariables}>
      <Loader />
    </div>
  )
}
