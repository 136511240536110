import style from './common.module.css'
import { VidzingLogo } from '../../../_images/vidzing-logo'

export type Props = {
  className?: string
}

export function LogoCopyright({ className }: Props): JSX.Element {
  const year = new Date().getFullYear()

  return (
    <div className={[style.wrapper, className].join(' ')}>
      <VidzingLogo className={style.logo} />
      <p>Copyright © {year} Vidzing</p>
    </div>
  )
}
