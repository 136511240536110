export default function YoutubeIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14212_228784)">
        <rect width="24" height="24" rx="12" fill="#444444" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8694 7.42645C19.1088 7.66591 19.2814 7.96392 19.3698 8.29082C19.8792 10.341 19.7615 13.5791 19.3797 15.7084C19.2913 16.0353 19.1187 16.3333 18.8792 16.5728C18.6398 16.8122 18.3418 16.9848 18.0149 17.0732C16.8182 17.3996 12.0017 17.3996 12.0017 17.3996C12.0017 17.3996 7.18521 17.3996 5.98851 17.0732C5.66161 16.9848 5.3636 16.8122 5.12414 16.5728C4.88468 16.3333 4.71213 16.0353 4.62367 15.7084C4.11136 13.6671 4.2518 10.4271 4.61378 8.30071C4.70224 7.97382 4.87479 7.6758 5.11425 7.43634C5.35371 7.19688 5.65172 7.02433 5.97861 6.93588C7.17532 6.6095 11.9918 6.59961 11.9918 6.59961C11.9918 6.59961 16.8083 6.59961 18.005 6.92598C18.3319 7.01444 18.6299 7.18699 18.8694 7.42645ZM14.4544 11.9996L10.4588 14.3139V9.68532L14.4544 11.9996Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14212_228784">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
