import type { RootState } from '..'
import { CreatorInterface } from '@shared/interfaces/creator.interface'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

export const creatorSelector = ({ creator }: RootState): CreatorInterface => {
  return creator
}

export const creatorChannelSelector = ({ creator }: RootState): ChannelInterface => {
  return creator.channel
}
