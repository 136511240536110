import style from './common.module.css'
import type { ReactNode } from 'react'
import { BigRegister } from './big-register'
import { CreatorLinks } from './creator-links'
import { Nav } from './nav'
import CopyRight from '@library/footer/copyRight'
import { ChannelPreviewLink } from '@library/footer/channel-preview-link'
import RenderIf from '@root/utils/render-if'
import { WhiteLabelInterface } from '@shared/interfaces/creators/white-label.interface'

export type Variant = 'default' | 'register' | 'linksAndRegister' | 'channelPreview' | 'no-pattern'

export type Props = {
  channelPathname?: string
  variant?: Variant
  whiteLabel?: WhiteLabelInterface
}

export function Footer({ channelPathname, variant = 'default', whiteLabel }: Props): JSX.Element {
  const hasWhiteLabel = !!whiteLabel

  const variantMap: Record<Variant, ReactNode> = {
    default: (
      <>
        <hr className={style.hr} />
        <CopyRight />
      </>
    ),
    register: (
      <>
        <BigRegister />
        <Nav className={style.nav} />
      </>
    ),
    linksAndRegister: (
      <>
        <CreatorLinks channelPathname={channelPathname as string} />
        <RenderIf isTrue={!hasWhiteLabel}>
          <BigRegister />
          <Nav className={style.nav} />
        </RenderIf>
      </>
    ),
    channelPreview: (
      <>
        <ChannelPreviewLink />
        <BigRegister />
        <Nav className={style.nav} />
      </>
    ),
    ['no-pattern']: (
      <>
        <Nav className={[style.nav, style.noPattern].join(' ')} />
      </>
    ),
  }

  return (
    <footer className={[style.footer, hasWhiteLabel ? style.noPaddingBottom : ''].join(' ').trim()}>
      <>{variantMap[variant]}</>
    </footer>
  )
}
