import style from './common.module.css'
import { ChannelName } from '../../_channel/channel-name'
import { LinkWrapper } from '../../_links/link-wrapper'
import { OpenLinkIcon } from '@library/_icons/open-link'
import { selectChannelPropByChannelPathname as sel } from '@store/channel-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import RenderIf from '@root/utils/render-if'
import { hasProp } from '@root/utils/has-prop'
import FacebookIcon from '@features/_ui/icons/facebook'
import InstagramIcon from '@features/_ui/icons/instagram'
import TwitterIcon from '@features/_ui/icons/twitter'
import YoutubeIcon from '@features/_ui/icons/youtube'
import UseSelectWhiteLabelData from '@hooks/white-label/use-select-white-label-data'
import EnvelopeIcon from '@ui/icons/envelope'
import { parseBoolean } from '@utils/connascence'
import XIcon from '@ui/icons/x'

export type Props = {
  channelPathname: string
  className?: string
}

export function CreatorLinks({ channelPathname, className }: Props): JSX.Element {
  const websiteUrl = useSel(sel(channelPathname)('websiteUrl'))
  const contactEmail = useSel(sel(channelPathname)('contactEmail'))
  // Get the hostname to display in the footer
  let websiteDomain = ''
  try {
    websiteDomain = new URL(websiteUrl).hostname
  } catch (e) {
    websiteDomain = websiteUrl
  }

  // Get social media links
  const facebookUrl = useSel(sel(channelPathname)('facebookUrl'))
  const instagramUrl = useSel(sel(channelPathname)('instagramUrl'))
  const twitterUrl = useSel(sel(channelPathname)('twitterUrl'))
  const youtubeUrl = useSel(sel(channelPathname)('youtubeUrl'))

  // Get white label data
  const { whiteLabel } = UseSelectWhiteLabelData()

  // Render the footer links from the white label data
  const renderFooterLinks = () => {
    if (whiteLabel && whiteLabel.footerLinks) {
      // create a copy of the footer links array, so it can be sorted
      const links = [...whiteLabel.footerLinks]
      return (
        <div className={style.footerLinksWrapper}>
          {links
            .sort((a, b) => a.order - b.order)
            .map((footerLink) => {
              return (
                <LinkWrapper
                  key={footerLink.name}
                  href={footerLink.url}
                  className={style.footerLink}
                  target="_blank"
                >
                  {footerLink.name}
                </LinkWrapper>
              )
            })}
        </div>
      )
    }
  }

  return (
    <section className={[style.wrapper, className].join(' ')}>
      <div className={style.innerWrapper}>
        <ChannelName channelPathname={channelPathname} hn={'h2'} className={style.channelName} />
        <div className={style.linkWrapper}>
          <RenderIf isTrue={hasProp(websiteUrl)}>
            <LinkWrapper href={websiteUrl} className={style.website} target="_blank">
              {websiteDomain} <OpenLinkIcon />
            </LinkWrapper>
          </RenderIf>
          <div className={style.socialWrapper}>
            <RenderIf isTrue={hasProp(facebookUrl)}>
              <LinkWrapper href={facebookUrl} className={style.social} target="_blank">
                <FacebookIcon />
              </LinkWrapper>
            </RenderIf>
            <RenderIf isTrue={hasProp(instagramUrl)}>
              <LinkWrapper href={instagramUrl} className={style.social} target="_blank">
                <InstagramIcon />
              </LinkWrapper>
            </RenderIf>
            <RenderIf isTrue={hasProp(twitterUrl)}>
              <LinkWrapper href={twitterUrl} className={style.social} target="_blank">
                <XIcon />
              </LinkWrapper>
            </RenderIf>
            <RenderIf isTrue={hasProp(youtubeUrl)}>
              <LinkWrapper href={youtubeUrl} className={style.social} target="_blank">
                <YoutubeIcon />
              </LinkWrapper>
            </RenderIf>
            <RenderIf isTrue={hasProp(contactEmail)}>
              <LinkWrapper href={`mailto:${contactEmail}`} className={style.scial}>
                <EnvelopeIcon />
              </LinkWrapper>
            </RenderIf>
          </div>
          {renderFooterLinks()}
        </div>
      </div>
    </section>
  )
}
