import { IconProps } from '@library/_types'

export default function CheckedOutIcon({ color }: IconProps): JSX.Element {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 0C23.6103 0 26.9971 0.956591 29.9209 2.63014L27.4557 5.83965C25.2293 4.66498 22.6923 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36C28.8366 36 36 28.8366 36 20C36 18.3719 35.7568 16.8006 35.3048 15.3205L38.1583 11.6055C39.3404 14.1581 40 17.002 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0Z"
        fill={color ?? 'url(#paint0_linear_12237_14765)'}
      />
      <path
        d="M38.475 5.24951C39.1651 4.38699 39.0252 3.1284 38.1627 2.43838C37.3002 1.74837 36.0416 1.88821 35.3516 2.75073L20.126 21.831L13.3515 15.7507C12.5372 15.0044 11.2721 15.0594 10.5257 15.8736C9.77932 16.6878 9.83432 17.953 10.6486 18.6994L20.5769 27.7507L38.475 5.24951Z"
        fill={color ?? 'url(#paint1_linear_12237_14765)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_12237_14765"
          x1="40"
          y1="20"
          x2="-2.28054"
          y2="8.1086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12237_14765"
          x1="40"
          y1="20"
          x2="-2.28054"
          y2="8.1086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
