import style from './common.module.css'
import { Button } from '../../_buttons'
import { VIDZING_HOME_PAGE } from '@root/constants'

export function BigRegister(): JSX.Element {
  return (
    <section className={style.section}>
      <div className={style.wrapper}>
        <div>
          <p>
            <strong>Launch your own channel</strong>
          </p>
          <p>We’re the easiest place to host, sell and stream your content.</p>
        </div>
        <Button className={style.ctaLaunchChannel} href={VIDZING_HOME_PAGE} variant={'secondary'}>
          Tell me more!
        </Button>
      </div>
    </section>
  )
}
