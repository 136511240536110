import style from './common.module.css'
import { LinkWrapper } from '../../_links/link-wrapper'
import { OpenLinkIcon } from '@library/_icons/open-link'
import { useSelector } from 'react-redux'
import { creatorChannelSelector } from '@store/creator-slice/_selectors'
import getHostName from '@library/_utilities/get-host-name'
import { Hn } from '@library/hn'

export function ChannelPreviewLink(): JSX.Element {
  const { websiteUrl, displayName } = useSelector(creatorChannelSelector)

  const hostName = getHostName(websiteUrl)

  return (
    <section className={style.wrapper}>
      <div className={style.innerWrapper}>
        <Hn className={style.channelName} hn="h2">
          {displayName}
        </Hn>
        {hostName && (
          <LinkWrapper href={websiteUrl} className={style.website} target="_blank">
            {hostName} <OpenLinkIcon />
          </LinkWrapper>
        )}
      </div>
    </section>
  )
}
