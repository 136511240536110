import styles from './styles.module.scss'
import { useAppSelector } from '@root/store'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import { getButtonCssVariables } from '@library/_buttons/button/_utils'
import CheckedOutIcon from '@ui/icons/checked-out'

export interface Props {
  channelPathname: string
  label: string
}

export default function AccessBadge({ channelPathname, label }: Props): JSX.Element {
  // Get channel theme from channel pathname
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  // Build cssVariable to be passed to component styles
  const cssVariables = getButtonCssVariables('primary', channelTheme)

  return (
    <div className={styles.badge} style={cssVariables}>
      <span className={styles.label}>{label}</span>
      <CheckedOutIcon color={channelTheme?.buttonColour} />
    </div>
  )
}
