export default function EnvelopeIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14980_163613)">
        <rect width="24" height="24" rx="12" fill="#444444" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 5.69995H5V16.2C5 17.3597 5.9402 18.2999 7.1 18.2999H16.9C18.0598 18.2999 19 17.3597 19 16.2V5.69995ZM6.4 8.0899V16.2C6.4 16.5865 6.7134 16.9 7.1 16.9H16.9C17.2866 16.9 17.6 16.5865 17.6 16.2V8.08992L12 13.6899L6.4 8.0899ZM16.6101 7.09995H7.38995L12 11.71L16.6101 7.09995Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14980_163613">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
