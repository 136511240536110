import style from './common.module.css'
import type { ReactNode } from 'react'

export type Props = {
  children: ReactNode
  title: ReactNode

  className?: string
}

export function LinkList({ children, title, className }: Props): JSX.Element {
  return (
    <section className={[style.wrapper, className].join(' ')}>
      <h2>{title}</h2>
      <ul className={style.ul}>{children}</ul>
    </section>
  )
}
